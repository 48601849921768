<template>
  <div class="record-wraper page-container">
    <TextHeader title="我的记录" />
    <div class="record-content">
      
    </div>
  </div>
</template>
<script>
import TextHeader from 'components/textHeader'
export default {
  name:"",
  data:()=>({}),
  methods:{
    getData(){
      this.$axiosRequest({
        name: "getValleyMessageDetail",
        ids: [this.$route.params.id],
      })
        .then((res) => {
          if (res.status === 200||res.status === 201) {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed:{
    updateTime(){
      if(this.data) return this.$dayjs(this.data.updated_at).format('YYYY/MM/DD')
      else return ''
    },
  },
  components:{
    TextHeader
  },
  watch:{},

}
</script>
<style lang="scss">
@import "constants/style/public";
.record-wraper{
  .record-content{
    background-color: $defaultBg;

  }
}
</style>